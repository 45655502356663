(function($){


//┐
//│  ╔══════════════════════════════════════════════════════════════════════════════════════════╗
//│  ║                                                                                          ║
//╠──╢  vars                                                                                    ║
//│  ║                                                                                          ║
//│  ╚══════════════════════════════════════════════════════════════════════════════════════════╝
//┘
var root_url = $("#data_url").data("url");
var api_url = root_url + "api/v1/";


$(function(){


	/* Menu */
	$("html" ).on("click","#nav-icon1",function(){
		toggleOverlay("#nav-icon1","#menumobil");
		console.log("cliked");			
	});
	$("html" ).on("click","#menumobil",function(){
		$("#nav-icon1,#menumobil").removeClass('open' );
		console.log("cliked");
	});
	
	//map
	if($(".map").length>0){
		$( ".map" ).each(function() {
		  var adrss= $( this ).data( "adress" );
		  var titre= $( this ).data( "titre" );
		  var id = $( this ).attr( "id" );
			map2(adrss,id,titre);
		});
		
	}
	//slidecontent();
	
	/* form */
	//$('select').material_select();
	
	    
	//cart();
	
	//┐
	//│  ╔══════════════════════════════════════════════════════════════════════════════════════════╗
	//│  ║                                                                                          ║
	//╠──╢  Parallax                                                                                ║
	//│  ║                                                                                          ║
	//│  ╚══════════════════════════════════════════════════════════════════════════════════════════╝
	//┘
	
	
	var parallaxElements = $('.parallax'),
	    parallaxQuantity = parallaxElements.length;
	
	$(window).on('scroll', function () {
	
	  window.requestAnimationFrame(function () {
		
		var stage_h = $(window).height();
		
	    for (var i = 0; i < parallaxQuantity; i++) {
	      var currentElement =  parallaxElements.eq(i);
	      var scrolled = $(window).scrollTop()-(i * stage_h);
	       //console.log(scrolled);  
	        currentElement.css({
	          'transform': 'translate3d(0,' + scrolled * 0.2 + 'px, 0)'
	        });
	
	    }
	  });
	
	});
	
})})(jQuery);


//┐
//│  ╔══════════════════════════════════════════════════════════════════════════════════════════╗
//│  ║                                                                                          ║
//╠──╢  Functions                                                                               ║
//│  ║                                                                                          ║
//│  ╚══════════════════════════════════════════════════════════════════════════════════════════╝
//┘

function map2(e,t,n){function u(e,t){if(t==google.maps.GeocoderStatus.OK){if(r!=null){r.setMap(null)}r=new google.maps.Marker({position:e[0].geometry.location,map:s,title:n});s.setCenter(e[0].geometry.location)}}var r=null;var i=new google.maps.LatLng(-34.397,150.644);var s=new google.maps.Map(document.getElementById(t),{mapTypeId:google.maps.MapTypeId.ROADMAP,zoom:16,scrollwheel:false,draggable:true,panControl:false,zoomControl:true,mapTypeControl:false,scaleControl:false,streetViewControl:false,overviewMapControl:false,maxZoom:22,minZoom:12,center:i});var o={address:e,region:"FR"};var a=new google.maps.Geocoder;a.geocode(o,u)}



function toggl(bt,cible) {
	if( $(bt).hasClass('open' ) ) {
		$(bt + ", " + cible).removeClass('open' );
	}else {
		$(bt + ", " + cible).addClass('open' );
	
	}
}

function toggleOverlay(lunchbt,overlay) {
	if( $(overlay).hasClass('open' ) ) {
		$(overlay + ", "+ lunchbt).removeClass('open' );
	}else {
		$(overlay + ", "+ lunchbt).addClass('open' );

	}
}





/* ################# Panier  ###################### */

